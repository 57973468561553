<template>
  <b-modal
    id="modal-additional-note"
    hide-footer
    title="Additional Note"
    centered
  >
    <vue-editor
      v-model="item"
      :editor-options="editorOptions"
      disabled
    />
  </b-modal>
</template>

<script>
export default {
  props: {
    item: {
      type: String,
      default: () => ''
    }
  },

  data: () => ({
    editorOptions: {
      modules: {
        toolbar: false
      }
    }
  })
}
</script>
